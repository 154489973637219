import imageOption from '../option/image';
import require_image from "$utils/images/require_image";
export default [
    {
        icon: require_image('images/service/1.png', imageOption),
        label: '需求树立&原型',
        content: '专业产品经历帮助您梳理项目需求，编写需求文档并制作产品原型。'
    },
    {
        icon: require_image('images/service/2.png', imageOption),
        label: 'UI设计',
        content: '不同风格，不同的专长的UI设计师为您设计产品界面，设计到您满意为止。'
    },
    {
        icon: require_image('images/service/3.png', imageOption),
        label: '程序开发',
        content: '经验丰富的开发者为您提供各种平台开发服务，专业项目经历管控研发流程，保障交付。'
    },
    {
        icon: require_image('images/service/4.png', imageOption),
        label: '产品测试',
        content: '专业测试工具，专属测试经历一级项目测试服务，为您的项目全面体检并出具专业报告。'
    },
    {
        icon: require_image('images/service/5.png', imageOption),
        label: '提交上线',
        content: '应用服务器环境搭建android在各大应用上架，ios端在AppStore提交上架。'
    }
];
