import imageOption from '../option/image';
import require_image from "$utils/images/require_image";
export default [
    {
        icon: require_image('images/diy/1.png', imageOption),
        title: '提供各行业解决方案',
        introduce: '提供了专业安全的互联网行业解决方案，为客户提供完美体验的产品'
    },
    {
        icon: require_image('images/diy/2.png', imageOption),
        title: '开发和运营相结合',
        introduce: '我们做的不单是软件开发、也为客户提供适合自己的推广运营方案，同时还有海量数据帮您引流',
        color: true
    },
    {
        icon: require_image('images/diy/3.png', imageOption),
        mobileIcon: require_image('images/diy/3-mobile.png', imageOption),
        title: '需求定位精准不走弯路',
        introduce: '告别模板开发，为每一个用户量身定制专属于自己的产品',
        color: true
    },
    {
        icon: require_image('images/diy/4.png', imageOption),
        mobileIcon: require_image('images/diy/4-mobile.png', imageOption),
        title: '物超所值的服务',
        introduce: '追求精细化的服务 力求每个环节做精做细，给到用户完美体验'
    }
];
