var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from "vue-property-decorator";
import { Button } from 'element-ui';
import require_image from '$utils/images/require_image';
import servicesData from "../data/servies";
import { vRow, vColumn, vBackground } from "$components/index";
import provisionData from '../data/provision';
import VImage from "$components/v-image/lib/main.vue";
// @ts-ignore
import VueCountUp from 'vue-countupjs';
import viewFooter from '$views/footer';
import diyData from '../data/diy';
import ScrollView from "$components/scroll-view/src/main.vue";
import imageOption from '../option/image';
import { vAnimateTiming, vAnimate, vAnimateItem } from '$components/index';
let default_1 = class extends Vue {
    constructor() {
        super(...arguments);
        this.imageOption = imageOption;
        // 技术提供
        this.provisionBackground = require_image('images/provision.png', imageOption);
        // 技术提供mobile
        this.provisionMobileBackground = require_image('images/provision-mobile.png', imageOption);
        // 技术提供数据
        this.provisionData = provisionData;
        // 完整服务
        this.servicesData = servicesData;
        // 公司介绍
        this.introduceImage = require_image('images/company.png');
        // 专注于定制
        this.diyData = diyData;
    }
    scroll(scroll) {
        return this.$refs.animate && this.$refs.animate.animate(scroll);
    }
};
default_1 = __decorate([
    Component({
        components: {
            ScrollView,
            VImage,
            [Button.name]: Button,
            vBackground,
            vRow,
            vColumn,
            viewFooter,
            VueCountUp,
            vAnimateTiming,
            vAnimate,
            vAnimateItem
        }
    })
], default_1);
export default default_1;
