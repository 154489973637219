export default [
    {
        introduce: '来自各领域的合作用户',
        number: 1000
    },
    {
        introduce: '业务解决方案',
        number: 100
    },
    {
        introduce: '标测标注',
        number: 200
    }
];
